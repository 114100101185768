import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import BasicGrid from './Nosotros';
import Caroulsel from './Caroulsel';
import TitlebarBelowImageList from './Productos';
import AsiLoHacemos from './Proceso';
import Clientes from './Clientes';
import Contacto from './Contacto';
// import CardMedia from '@mui/material/CardMedia';

import './static/styles.css';


const drawerWidth = 350; // ancho del menu

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`, // Control para reducir espacio cuando sale el menu
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // marginLeft: 0, // Si comento esto el menu sale sobre el contenido
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color='inherit' open={open} sx={{height:{xs:'60px', lg:'51px'}}}>
        <Toolbar variant='dense'>
          <IconButton
            // color="inherit"
            size='large'
            aria-label="open drawer"
            onClick={ open? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{ color: 'black', ...(open) }}
          >
            <MenuIcon sx={{fontSize:'45px'}}/>
          </IconButton>
          <Box display='flex' justifyContent='center' marginLeft={{xs:'25%', md:'35%', lg:'45%'}} marginRight={{xs:'25%', md:'35%', lg:'45%'}}>
            <img src='/assets/images/Vimotex_Logo.png' width='100%' alt='logo'/>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{height:{xs:'60px', lg:'51px'}}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Inicio', 'Nosotros', 'Nuestros productos', 'Asi lo hacemos', 'Nuestros clientes', 'Contacto'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton href={'#'+text} onClick={handleDrawerClose}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} primaryTypographyProps={ {fontFamily:'Helvetica', fontSize:22} }/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader id='Inicio'/>
        <Caroulsel />
        <BasicGrid/>
        <TitlebarBelowImageList/>
        <AsiLoHacemos/>
        <Clientes/>
        <Contacto id='Contacto'/>
      </Main>
      <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        />
        <a
          href="https://api.whatsapp.com/send?phone=573012109017&text=Hola Vimotex quisiera más información"
          className="float"
          target="_blank"
          rel='noreferrer'
        >
          <i className="fa fa-whatsapp my-float"></i>
          <h1 id="circle">¿Necesitas Ayuda?</h1>
        </a>
      {/* <CardMedia
        sx={{ width: 80, height: 80 , position: 'fixed', bottom: 60, right: 30, zIndex: 200}}
        image='/assets/images/iconos/Recurso 2.png'
        title=""
      /> */}
    </Box>
  );
}