import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


function MediaCard({item}) {
  return (
    <Card sx={{ maxWidth: {sx:500 , lg:250}, boxShadow: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <CardMedia
        sx={{ width: 200, height: 200 , padding: 5}}
        image= {item.img}
        title={item.title}
      />
      <CardContent sx={{}}>
        <Typography variant="body2" color="text.primary" textAlign='center' fontSize={{xs: 20, md:16, lg: 14}} fontFamily='Helvetica Neue' >
          <strong>{item.title}</strong>
        </Typography>
      </CardContent>
      </Box>
    </Card>
  );
}

export default function TitlebarBelowImageList() {
  return (
    <Box id='Nuestros productos' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}>
      <Typography  variant='h3' padding={10} paddingBottom={0} fontFamily='Helvetica Neue'>
        <strong>NUESTROS PRODUCTOS</strong>
      </Typography>
      <Grid container spacing={{ xs: 2, md: 4, lg: 0 }} padding={{ xs: 7, md: 1, lg: 12 }}>
        {itemData.map((proc, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index} sx={{ padding: '5px', display: 'flex', justifyContent: 'center' }}>
            <MediaCard key={proc.id} item={proc} sx={{ display: 'initial' }} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const itemData = [
  {
    id: 1,
    img: '/assets/images/productos/1.jpg',
    title: 'CAMISETAS HOMBRE',
    author: 'Camilo Rojas',
  },
  {
    id: 2,
    img: '/assets/images/productos/2.jpg',
    title: 'CAMISETAS MUJER',
    author: 'Camilo Rojas',
  },
  {
    id: 3,
    img: '/assets/images/productos/3.jpg',
    title: 'CAMISETAS NIÑO',
    author: 'Camilo Rojas',
  },
  {
    id: 4,
    img: '/assets/images/productos/4.jpg',
    title: 'POLOS',
    author: 'Camilo Rojas',
  },
  {
    id: 5,
    img: '/assets/images/productos/5.jpg',
    title: 'GORRAS',
    author: 'Camilo Rojas',
  },
  {
    id: 6,
    img: '/assets/images/productos/6.jpg',
    title: 'HOODIES',
    author: 'Camilo Rojas',
  },
  {
    id: 7,
    img: '/assets/images/productos/7.jpg',
    title: 'JOGGERS',
    author: 'Camilo Rojas',
  },
  {
    id: 8,
    img: '/assets/images/productos/8.jpg',
    title: 'PANTALONETAS',
    author: 'Camilo Rojas',
  },
  // {
  //   img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
  //   title: 'Mushrooms',
  //   author: '@silverdalex',
  // },
  // {
  //   img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
  //   title: 'Tomato basil',
  //   author: '@shelleypauls',
  // },
  // {
  //   img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
  //   title: 'Sea star',
  //   author: '@peterlaster',
  // },
  // {
  //   img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
  //   title: 'Bike',
  //   author: '@southside_customs',
  // },
];