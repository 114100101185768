import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


function MediaCard({item}) {
  return (
    <Card sx={{ maxWidth: {sx:500 , lg:250}, boxShadow: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 0, pb: 0 }}>
        <CardMedia
        sx={{ width: {xs: 50, lg:80}, height: {xs: 50, lg:80} , padding: {xs: 3, md:4, lg:5}}}
        image= {item.imagen}
        title=""
      />
        <Typography variant='subtitle1' sx={{textAlign: 'left', paddingLeft: {xs: 1, lg:2}}} fontSize={{xs: 16, md:20 , lg: 16}} fontFamily='Helvetica Neue' >
          <b>{item.titulo}</b>
        </Typography>
      </Box>

      <CardContent sx={{padding:0, paddingTop: 2}}>
        <Typography variant="body2" color="text.primary" textAlign='justify' fontSize={{xs: 14, md:16, lg: 14}} fontFamily='Helvetica' >
          <strong>{item.descripcion}</strong>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default function ResponsiveGrid() {
    return (
      <Box id='Asi lo hacemos' sx={{ marginTop: '5%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant='h3' paddingTop={10} padding={10} paddingBottom={0} fontFamily='Helvetica Neue' >
            ASÍ LO HACEMOS...
        </Typography>
        <Grid container spacing={{ xs: 8, md: 5, lg: 0 }}  padding={{xs: 8, md: 10, lg:12}}>
          {proceso.map((proc, index) => (
            <Grid item xs={6} sm={6} md={4} lg={3} key={index} sx={{ padding: '5px',  display: 'flex', justifyContent: 'center' }}>
              <MediaCard key={proc.id} item={proc} sx={{ display: 'initial' }}/>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

const proceso = [
    {
        id: 1,
        imagen: '/assets/images/iconos/Recurso 4.png',
        titulo: 'ATENCIÓN',
        descripcion: `Antes de empezar, debes tener en cuenta que tenemos un mínimo de fabricación 1080 unidades, distribuidas
        en mínimo 120 unidades por referencia y/o color, es decir, 9 referencias mínimas por orden de compra.`
    },
    {
        id: 2,
        imagen: '/assets/images/iconos/Recurso 5.png',
        titulo: 'FICHA TÉCNICA',
        descripcion: `Envíanos una ficha técnica o muestra física con la mayor información posible sobre el producto
        que deseas desarrollar.`
    },
    {
        id: 3,
        imagen: '/assets/images/iconos/Recurso 6.png',
        titulo: 'INSUMOS',
        descripcion: `Seleccionamos materiales e insumos y te asesoramos en cuál sería la mejor opción para tu producto..`
    },
    {
        id: 4,
        imagen: '/assets/images/iconos/Recurso 7.png',
        titulo: 'MUESTRA FÍSICA',
        descripcion: `Desarrollamos una muestra y te enviamos la cotización.`
    },
    {
        id: 5,
        imagen: '/assets/images/iconos/Recurso 8.png',
        titulo: 'APROBACIÓN',
        descripcion: `Cuando la muestra sea aprobada empezamos nuestro proceso de fabricación.`
    },
    {
        id: 6,
        imagen: '/assets/images/iconos/Recurso 3.png',
        titulo: 'CORTE',
        descripcion: `Aquí empieza nuestra magia, para que el resultado final sea el que estas esperando.`
    },
    {
        id: 7,
        imagen: '/assets/images/iconos/Recurso 9.png',
        titulo: 'CONFECCIÓN',
        descripcion: `Contamos con maquinaria de última tecnología, que nos permite fabricar prendas con los más altos
        estándares de calidad.`
    },
    {
        id: 8,
        imagen: '/assets/images/iconos/Recurso 11.png',
        titulo: 'ESTAMPADO BORDADO SUBLIMADO',
        descripcion: `Hacemos el proceso de valor agregado según tu requerimiento, tenemos muchas técnicas y texturas
        innovadoras que te encantarán.`
    },
    {
        id: 9,
        imagen: '/assets/images/iconos/Recurso 10.png',
        titulo: 'EMPAQUE',
        descripcion: `Tu nos dices en qué presentación quieres recibir tu producto. (Etiquetas, códigos, sellos de
        prestigio y/o bolsas por unidad o paquete).`
    },
]

