import { Paper } from '@mui/material'
import Typography from '@mui/material/Typography';
import './static/styles.css'

function Item({item})
{
    return (
        <Paper elevation={0} sx={{position: 'relative'}}>
            <picture>
                <source srcSet={item.image} media='(min-width: 1400px)'/> 
                <source srcSet={item.image2} media='(min-width: 769px)'/> 
                {/* <source srcSet={item.image3} media='(min-width: 577px)'/>  */}
                <img src={item.image3} alt={item.title} style={{ width:'100%', height:'100vh'}} id='Inicio' />
            </picture>
            <Typography variant='h1' className='centrado' color='white' fontFamily='Helvetica Neue' fontSize={48}>
                <b>PAQUETE COMPLETO</b>
            </Typography>
        </Paper>
    )
}

export default Item;
//height={{xs:'100vh', lg:'90vh'}}