import * as React from 'react';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';

// maxWidth: '70%' , card

const item = 
  {
    id: '1',
    image: '/assets/images/nuestros_clientes.png',
    image2: '/assets/images/nuestros_clientes2.png',
    title: '',
  }

export default function MediaCard() {
  return (
    <Card id='Nuestros clientes' sx={{ boxShadow: 'none', marginTop: '5%', display: 'flex', flexDirection: 'column', padding: 2 }}>
        <Typography variant='h3' padding={15} paddingTop={10} paddingBottom={0} fontFamily='Helvetica Neue'>
            <strong>NUESTROS CLIENTES</strong>
        </Typography>
      {/* <CardMedia
        sx={{ height: 600 }}
        image="/assets/images/nuestros clientes.png"
        title=""
      /> */}
      <picture>
        <source srcSet={item.image2} media='(min-width: 1400px)' />
        {/* <source srcSet={item.image2} media='(min-width: 769px)' /> */}
        {/* <source srcSet={item.image3} media='(min-width: 577px)'/>  */}
        <img src={item.image2} alt={item.title} style={{ width: '100%', padding: '8%' }} />
      </picture>
    </Card>
  );
}