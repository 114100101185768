import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function BasicGrid() {
  return (
    <Box id='Nosotros' sx={{ flexGrow: 1 }}>
      <Grid container alignItems='center' padding={10} paddingTop={30} >
        <Grid item xs={12} md={6} lg={6} display='flex' justifyContent='center' marginBottom={10}>
            <img src='/assets/images/Vimotex_Logo.png' width='70%' alt='logo'/>
        </Grid>
        <Grid item xs={12} md={6} lg={6} display='flex' flexDirection='column'>
            <Typography paragraph variant='h3' paddingBottom={4} align='center' fontFamily='Helvetica Neue'>
                <strong>NOSOTROS</strong>
            </Typography>
            <Typography variant='h6' align='justify' padding={{xs: 0, lg: 10}} fontSize={{xs: 26, md:22, lg: 26}} fontFamily='Helvetica'>
              Somos una empresa Colombiana, dedicada al desarrollo y fabricación de prendas de vestir en tejido de punto.
              Desde el 2013 hemos brindado a nuestros clientes productos con los más altos estandares de calidad y valor
              agregado. ¡Tú plasma la idea, nosotros la hacemos realidad!.                
            </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}