import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function Contacto() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: '20%', marginBottom: '15%' }}>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={6} lg={6} padding={5}>
            <Typography paragraph variant='h3' align='center' fontFamily='Helvetica Neue'>
                <strong id='Contacto'>CONTACTO</strong>
            </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <Typography paragraph variant='h5'>
                WhatsApp +57 3012109017
                <br/>
                gerencia@vimotex.com
            </Typography>
        </Grid>
        {/* <Grid item xs={0} md={1} lg={2}/> */}
      </Grid>
    </Box>
  );
}