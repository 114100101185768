import './App.css';
import PersistentDrawerLeft from './Components/SideBar'

function App() {
  return (
    <div className="App">
      <PersistentDrawerLeft></PersistentDrawerLeft>
    </div>
  );
}

export default App;
